import React from 'react'
import Layout from "../../../../components/layout";
import HeaderPaginas from '../../../../components/headerPaginas'
import Seo from '../../../../components/SEO/SEO';
import CtaServicios from '../../../../components/ctaServicios';



export default function Formacion(props) {

    return (
        <Layout>
            <Seo pathname={props.location.pathname} />
            <div className="cabecera-servicio">
                <HeaderPaginas titulo="Formación y concienciación" imagen="/img/cabeceras/formacion-concienciacion-personas-ciberseguridad-bullhost.jpg" />
                <div className="intro-servicio">
                    <p>En ciberseguridad tendemos a centrarnos en la protección informática, cuando las estadísticas muestran que el principal factor de riesgo es el ser humano.</p>
                </div>
            </div>
            <div className="cuerpo-servicio">
                <section className="limitador">
                    <p className="parrafo-destacado">A veces, por falta de atención y, en otras ocasiones, por  desconocimiento, pero el factor humano es determinante en los ataques informáticos, y los ciberdelincuentes lo saben.</p>
                    <h2>¿Qué se puede hacer para mitigar los riesgos de ciberseguridad en cuanto a las personas?</h2>
                    <div className="fila-cards-cpd-propio-caracteristicas">
                        <div className="card-cpd-propio-caracteristicas">
                            <div className="col-imagen" style={{ backgroundImage: "url(/img/formacion-concienciacion-secundaria-bullhost.jpg)" }}>
                                {/* <StaticImage src="../../../static/img/bullhost-cloud-robusto-cpd.jpg" alt="Cloud robusto" /> */}
                            </div>
                            <div className="col-texto">
                                <div>
                                    <h3>Formar y concienciar: son los pilares de la ciberseguridad</h3>
                                    <p>En Bullhost disponemos de un servicio de concienciación y formación en ciberseguridad pensado para que las organizaciones puedan conocer, con datos reales, el nivel de riesgo que suponen sus trabajadores; y por otro lado, cómo combatirlo con una serie de acciones como la formación, tanto online como presencial, además de un entrenamiento específico y personalizado ante situaciones que simulan ser ataques reales.</p>
                                    <p>La efectividad de este servicio es un hecho. En tan sólo seis meses se pasa de situaciones iniciales donde el 25% de los usuarios “vigilados” pinchan en enlaces falsos, ejecutan archivos incorrectos o dan de manera verbal más datos de los necesarios, a reducir este 25% a un porcentaje residual y poco significativo.</p>
                                    <p>En nuestro servicio de formación y concienciación lo importante no es ni salir mal en la foto inicial,ni “fiscalizar” a los usuarios, sino conocer el nivel de riesgo real existente y planificar cómo mejorarlo convirtiéndolo en una tendencia organizativa.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="limitador contenido__servicio">
                    <p className="parrafo-destacado">A veces, por falta de atención y, en otras ocasiones, por  desconocimiento, pero el factor humano es determinante en los ataques informáticos, y los ciberdelincuentes lo saben.</p>
                </section>
                <section className="limitador bloque-grid">
                    <div className="titulo destacado">
                        <h2>¿qué se puede hacer para mitigar los riesgos de ciberseguridad en cuanto a las personas?</h2>
                    </div>
                    <div className="texto">
                        <p>Formar y concienciar: son los pilares de la ciberseguridad</p>
                        <p>En Bullhost disponemos de un servicio de concienciación y formación en ciberseguridad pensado para que las organizaciones puedan conocer, con datos reales, el nivel de riesgo que suponen sus trabajadores; y por otro lado, cómo combatirlo con una serie de acciones como la formación, tanto online como presencial, además de un entrenamiento específico y personalizado ante situaciones que simulan ser ataques reales.</p>
                        <p>La efectividad de este servicio es un hecho. En tan sólo seis meses se pasa de situaciones iniciales donde el 25% de los usuarios “vigilados” pinchan en enlaces falsos, ejecutan archivos incorrectos o dan de manera verbal más datos de los necesarios, a reducir este 25% a un porcentaje residual y poco significativo.</p>
                        <p>En nuestro servicio de formación y concienciación lo importante no es ni salir mal en la foto inicial,ni “fiscalizar” a los usuarios, sino conocer el nivel de riesgo real existente y planificar cómo mejorarlo convirtiéndolo en una tendencia organizativa.</p>
                    </div>
                </section> */}
            </div>
                {/* <section className="bloques-partidos">
                    <section className="bloque-acento">
                    <div className="limitador contenido__servicio">
                        <h2 className="titulo_contenido">¿Quieres saber si es seguro tu dominio?</h2>
                        <p>Nuestro equipo ha desarrollado una app para que puedas saber si tu dominio es seguro y si, por tanto, estás en riesgo con tu email.</p>
                        <p><strong>¡En menos de 1 minuto tendrás el resultado! ¿Te atreves?</strong></p>
                        <p>Esta aplicación, creada en Python por <a href="https://bullhost.agency" target="_blank" rel="noreferrer noopener">bullhost.agency</a>, permite verificar en un momento si un correo corporativo es suplantable.</p>
                        <Boton color="secundario" url="https://esmidominioseguro.bullhost.es/formulario/" texto="Accede a la app" externo={true} />
                        </div>
                    </section>
                    <section className="bloque-acento" style={{backgroundImage: "url(/img/bullhito_escalado.jpg)"}}>
                        <div className="link-app">
                        </div>
                    </section>
                </section> */}
            <CtaServicios/>
        </Layout>
    )
}